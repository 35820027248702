section.hero-section.row {
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;
  max-height: 600px;
  padding: 0;
  display: flex;
  justify-content: center;
    a {
        width: 17%;
        max-width: 17%;
        .hero.jumbotron {
          margin: 0;
          max-width: 100%;
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
          color: $light;
          padding: 0;
          height: 100%;
        .hero_unit.box {
          display: none;
          height: 100%;
          width: 100%;
          display: flex;
          flex-flow: column wrap;
          justify-content: center;
          transition: all 400ms;
          h1 {
              opacity: 0;
              font-family: "junction", sans-serif;
              font-size: 1.5em;
              text-transform: uppercase;
              text-align: center;
              height: 100%;
              width: 100%;
              margin: 0;
              display: flex;
              flex-flow: row wrap;
              justify-content: center;
              align-items: center;
              transition: all 400ms;
              padding: 0 1em;
              line-height: 1.5em;
              &:hover {
                opacity: 1;
                transition: all 400ms;
              }
          }
          &:hover {
            display: block;
            background: rgba(51, 12, 47, 0.75);
            transition: all 400ms;
          }
        }
    }
}
}

// Overlay
.hero-section {
  position: relative;
  overflow: hidden;
}
.overlay:after {
  display: block;
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
}

@media screen and (min-width: 901px) {
  section.hero-section.row {
          .hero.jumbotron {
            height: 70vh;
            min-height: 550px;
      }
}
}

@media screen and (max-width: $large) {
  section.hero-section.row {
          .hero.jumbotron {
            border-left: 1px $light solid;
            border-right: 1px $light solid;
            .hero_unit.box {
              display: block;
              background: rgba(51, 12, 47, 0.75);
              h1 {
                opacity: 1 !important;
                font-size: 1.25em !important;
                line-height: 1.25em !important;
            }
          } // hero unit
      } // jumbo
} // section
}

@media screen and (max-width: $medium) {
  section.hero-section.row {
    column-count: 3 !important;
    max-height: 100vh !important;
    height: auto !important;
    flex-flow: column wrap;
      a {
        max-width: 100% !important;
        width: 100%;
          .hero.jumbotron {
            min-height: 15vh;
            border-left: none;
            border-right: none;
            border-bottom: 1px $light solid;
            .hero_unit.box {
              min-height: 15vh;
              h1 {
                min-height: 15vh;
            }
          } // hero unit
      } // jumbo
  } // a
} // section
}
