section.cta.row {
    position: relative;
    padding: 0;
    height: 400px;
    display: flex;
    .ctaOverlay {
        background: $plum;
        opacity: .9;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    .ctaContent.container {
        z-index: 2;
        color: $light;
        text-align: center;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        p {
          margin-bottom: 2em;
        }
    }
}
