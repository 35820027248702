/* Accessibility Friendly Navigation */
nav.main-navigation {
  text-align: left;
  z-index: $header-nav-zindex;
  button.menu-toggle {
    position: fixed;
    bottom: 10px;
    left: 1em;
    padding: 10px 20px;
    z-index: $header-nav-zindex;
    transition: 400ms bottom ease;
    &.nav-hide {
      bottom: -50px;
    }
  }
  .menu-item a {
    line-height: $fields-height;
    display: block;
    position: relative;
    text-decoration: none;
  }
  /* General */
  li {
    position: relative;
    //padding: 0 20px;

  }
  ul {
    display: none;
    list-style: none;
    margin: 0;
    &.toggled-on {
      display: block;
      > ul {
        display: block;
      }
    }
  }
  ul ul {
    display: none;
    padding: .8em;
  }
  .current-menu-item > a,
  .current-menu-ancestor > a {
    font-weight: 700;
  }
  &.toggled .nav-menu {
    display: block;
  }
  .nav-menu {
    padding: 1em;
    margin-bottom: 4em;
    background: $mobile-nav-popup-background;
  }
  .nav-menu > ul > li:first-child,
  .nav-menu > li:first-child {

  }
  .menu-item-has-children > a {
    padding-right: 30px;
  }
  .menu-item-description {
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0.5em;
  }
  .dropdown-toggle {
    background-color: transparent;
    border: 0;
    box-sizing: content-box;
    content: "";
    height: 27px;
    width: 27px;
    top: 7px;
    right: 0px;
    padding: 0;
    position: absolute;
    text-transform: lowercase; /* Stop screen readers to read the text as capital letters */
    &:after {
      content: "\f107";
      font-family: 'FontAwesome';
      font-size: 1em;
      line-height: 27px;
      position: relative;
      top: 2px;
      width: 42px;
    }
    &:hover,
    &:focus {
    }
    &:focus {
      outline: 1px solid #c1c1c1;
      outline: 1px solid rgba(51, 51, 51, 0.3);
    }
    &.toggle-on:after {
      content: "\f106";
    }
  }
  &.toggled {
    position: fixed;
    bottom: 0;
    right: 1em;
    left: 1em;
    z-index: $header-nav-zindex;
    a {
    }
  }
  &.toggled ul.nav-menu {
    max-height: calc(100vh - 8em);
    overflow-y: auto;
  }
  /* Mobile Menu & Button Colors */
  a {
    font-family: $font-secondary;
  }
  a, i:before, button:after {
    color: $mobile-nav-link-color;
  }
  button.menu-toggle {
    background: $mobile-nav-button-background;
    color: $mobile-nav-button-text;
    border: 1px solid $mobile-nav-border-color;
  }
}

.no-js .main-navigation ul ul {
  display: block;
}

.menuBottom {
  display: none;
}

@media screen and (min-width: $medium) {
  header.banner .menuHead {
    background: $lightgray;
    color: $nav-text-color;
    font-weight: 100;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    #headerMenu {
      display: flex;
      .main-navigation {
        width: 100%;
        display: flex;
        ul#menu-primary {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          li {
            a {
              color: $nav-link-color;
              font-family: $font-primary;
              text-transform: uppercase;
              letter-spacing: 1px;
              &:hover {
                color: $plum;
              }
            } //a
          } //li
        } //primary
      } //main nav
    } //headerMenu
  } //header
  .menu-item-has-children.menu-design-galleries {
    display: none !important;
}
  button.menu-toggle {
    display: none;
  }
  #headerMenu {
    a, i:before, button:after {
      color: $nav-link-color;
    }
    a:hover,
    a:focus,
    i:hover:before,
    i:focus:before,
    button:focus:after,
    button:hover:after {
      color: $nav-link-color-hover;
    }
  }
  nav.main-navigation {
    color: $nav-text-color;
    // Link Color
    li {
      display: inline-block;
    }
    .nav-menu {
      padding: 0;
      display: block;
      background: transparent;
      margin: 0;
    }
    .sub-menu {
      background: $nav-dropdown-background;
      z-index: $header-nav-zindex;
      padding: 0 1em;
      text-align: left;
      li {
        padding: 0;
      }
      a, i:before, button:after {
        color: $light !important;
      }
      &.toggled-on {
        position: absolute;
        right: 0;
      }
      .sub-menu {
        position: initial !important;
        padding-left: 1em;
      }
    }
  }
  .menuBottom {
    display: block;
    background: $nav-background-color;
    .container {
      padding: 0;
      nav#gallery-navigation {
        width: 100%;
        ul#menu-gallery-menu {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: center;
            margin: 0.5em 0;
            li.menu-item a {
                color: $light;
                text-transform: uppercase;
                &:hover {
                    color: $nav-link-color-hover;
                }
            }
        }
    }
    }
  }
}
