body#tinymce {
    color: gray;
}
html {
  font-size: 1em;
}
body {
  background-color: $sage-body-background;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.45;
  color: $light;
  font-family: $font-primary;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  position: relative;
  background-size: cover;
}
#wpadminbar {
  margin-top: -46px;
}
@media screen and (min-width: 600px) {
  #wpadminbar {
    margin-top: 0;
  }
}
p {
  margin: 1em auto 0 auto;
  max-width: $paragraph-width;
}
h2,
h3,
h4 {
  margin: 0 0 0.5em;
  font-weight: 100;
  line-height: 1.2;
  letter-spacing: 1px;
  font-family: $font-primary;
  text-transform: uppercase;
}
@media screen and (min-width: $small) {
  h1 {
    margin-top: 0;
    font-size: 3.998em;
    font-family: $font-secondary;
    margin: 1em 0 0.5em;
    font-weight: 100;
    line-height: 1.2;
  }
  h2 {
    font-size: 2.5em;
  }
  h3 {
    font-size: 1.999em;
  }
  h4 {
    font-size: 1.414em;
  }
}
.font_small,
small {
  font-size: 0.707em;
}
li {
  list-style: none;
  padding-left: 1em;
}
ul {
  padding: 0;
}
a {
  color: $body-links;
  transition: all 400ms;
  &:hover {
    color: $body-links-hover;
    text-decoration: none !important;
    transition: all 400ms;
  }
}
main.main {
  padding: 0;
  margin-bottom: 5em;
}
@media screen and (min-width: 1500px) {
  .container {
    width: 90%;
  }
}
.row {
  margin: 0;
  padding: 3em 0;
}
.content.row {
  padding: 0;
}
.box {
  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;
}
img {
  max-width: 100%;
}
input {
  color: $gray;
  line-height: $fields-height;
  border: 1px solid $lightgray;
  padding: 0;
  font-weight: 300;
}
input[type=search],
input[type=text],
textarea {
  text-indent: 1em;
}
.dark {
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: $light;
  }
}
