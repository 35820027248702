.page-header {
  border-bottom: 1px solid $light;
}

.wrap.container {
    background: rgba(51, 12, 47, 0.85);
    width: 100%;
    .content.row {
        max-width: 750px;
        margin: 0 auto;
        main.main {
          width: 100%;
          float: none;
        }
    }
}

.page.about, .page.contact, .page.weddings-events, .page.sympathy-funerals, .page.home-decor-gifts, .page.custom-designs {
  aside.sidebar {
    display: none;
  }
}

// ABOUT
body.page.about {
    background: url(/wp-content/uploads/about_background.jpg) center center no-repeat fixed;
}

// CONTACT
body.page.contact {
    background: url(/wp-content/uploads/contact_background.jpg) center center no-repeat fixed;
}

// WEDDINGS & EVENTS
body.page.weddings-events {
    background: url(/wp-content/uploads/weddings_background.jpg) center center no-repeat fixed;
    main.main li {
        list-style: disc inside none;
    }
}

// SYMPATHY & FUNERALS
body.page.sympathy-funerals {
    background: url(/wp-content/uploads/sympathy_background.jpg) center center no-repeat fixed;
}

// HOME DECOR & GIFTS
body.page.home-decor-gifts {
    background: url(/wp-content/uploads/gifts_background.jpg) center center no-repeat fixed;
}

// CUSTOM DESIGNS
body.page.custom-designs {
    background: url(/wp-content/uploads/custom_background.jpg) center center no-repeat fixed;
}

// REQUEST AN ESTIMATE
body.page.request-an-estimate {
  background: url(/wp-content/uploads/quote_background.jpg) top center no-repeat fixed;
}
