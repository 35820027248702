body.blog, body.archive, body.category, body.single-post {
  background: url(/wp-content/uploads/contact_background.jpg) center center no-repeat fixed;
  .content.row {
      max-width: 95% !important;
      main.main {
          width: 750px !important;
          float: left !important;
      }
      aside.sidebar {
          float: right;
          margin-top: 5em;
          color: $light !important;
          .widget {
              border-color: $light;
          }
      }
      section#comments h2 {
          font-size: 1.5em;
      }
      h3#reply-title {
          font-size: 1.5em;
      }
      li.entry-share-btn {
          background: $pink;
      }
  }
}

.single-post {
  .entry-content {
      border-bottom: 1px solid $light;
      margin-bottom: 1.5em;
      padding-bottom: 1em;
  }
  footer {
      display: none;
  }
}

@media screen and (max-width: 1280px) {
  .single-post, .blog, .archive, .category {
.wrap.container .content.row main.main {
    max-width: 100% !important;
    border-bottom: 1px $light solid;
    margin-bottom: 2em;
    padding: 2em;
    max-width: 100% !important;
    width: 100% !important;
}
aside.sidebar {
    max-width: 100% !important;
    float: none !important;
    width: 100%;
    margin-top: 0 !important;
    section.widget {
        max-width: 100% !important;
    }
}
  }
}
