//
// Homepage
//
.home {
  header.banner {
    position: fixed;
    left: 0;
    right: 0;
    box-shadow: #333 0 0 7px;
  }
  .menuBottom {
    display: none;
  }
  .menuHead {
    background: $nav-background-color !important;
    a {
      color: $light;
      &:hover {
        color: $nav-link-color-hover !important;
      }
    }
  }
main.main {
    padding: 121px 0 0 0;
    margin-bottom: 0;
}
}
// About
.home {
  section.row.homeAbout {
    text-align: center;
    background-color: $lightgray;
    .aboutContent {
      margin-bottom: 1em;
    }
    a:hover {
      color: $light;
    }
  }
}
// Testimonials
.home {
  section.homeTest.row {
    background-color: $light;
    color: $header-color;
    text-align: center;
    padding-bottom: 2.5em;
    .testContent.container {
      column-count: 2;
      margin-top: 2em;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    .testLogo img {
    margin-bottom: 1em;
    padding: 0.5em;
}
}
  }
}
// Contact
section.row.homeContact {
    text-align: center;
}


@media screen and (max-width: $medium) {
  .home {
    h2 {
    font-size: 1.5em;
}
.aboutContent {
    max-width: 550px;
    margin: 0 auto 1em;
}
  }
}

@media screen and (max-width: 675px) {
  .home {
    main.main {
    padding: 0;
}
.testContent.container {
    flex-flow: column wrap !important;
    .testLogo img {
        border: 2px $plum solid;
    }
}
}
}

@media screen and (max-width: 600px) {
.home {
  .box.dark {
    margin: 0 1em;
    text-align: left;
    a.button.dark {
        margin-left: 0 !important;
    }
}
}
}
