// Glyphicons font path
$icon-font-path: "../fonts/";

// Fonts
$font-primary: 'Junction', sans-serif;
$font-secondary: 'brush-script-std', sans-serif;

// !important -  Responsible for cohesive input height & button height
$fields-height: 2.5em;

// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;

/* !important: content should never be more than 55-ish characters per line, it just becomes unreadable */
$content-width: 42em;
$paragraph-width: 40em;

// Media Query Sizes
$small: 600px;
$medium: 900px;
$large: 1100px;

// Menu & Header helpers
$header-nav-zindex: 999;

/*
 * Colors
 */
$dark: #333;
$light: #FFF;
$gray: #737373;
$lightgray: #c3c3c3;

// Brand Colors Specifics - Delete these value and update the rest of the sheet to whatever you want!
$plum: #330C2F;
$pink: #B73D61;
$mint: #A1CDA8;

// General
$sage-body-background: $light;
$text-color: $gray;
$header-color: $plum;
$body-links: $pink;
$body-links-hover: $mint;

// Header
$header-background-color: $light;
$info-link-color: $pink;
$info-link-color-hover: $gray;
$nav-background-color: $mint;

// Non-Mobile Nav
$nav-text-color: $light; /* probably not needed, but just in case */
$nav-link-color: $light;
$nav-link-color-hover: $gray;
$nav-dropdown-background: $mint;

// Mobile Nav Button
$mobile-nav-button-text: $light;
$mobile-nav-border-color: $light;
$mobile-nav-button-background: $pink;

// Mobile Nav Popup
$mobile-nav-popup-background: $pink;
$mobile-nav-text-color: $light;
$mobile-nav-link-color: $light;

// Buttons
$button-background: $pink;
$button-border: $pink;
$button-text: $light;
// Buttons:hover
$button-background-hover: transparent;
$button-border-hover: $light;
$button-text-hover: $light;

// Blurbs
$blurb-heading-color: $plum;
$blurb-text-color: $gray;
// Blurb Icons
$blurb-icon: $light;
$blurb-icon-border: $mint;
$blurb-icon-background: $mint;
// Blurb Icon Hover
$blurb-icon-hover: $light;
$blurb-icon-border-hover: $lightgray;
$blurb-icon-background-hover: $lightgray;
