.woocommerce-cart table.cart input,
.woocommerce-cart table.cart td.actions .coupon .input-text {
  line-height: $fields-height;
  padding: 0;
}
.woocommerce-page input#coupon_code {
  text-indent: 0.5em;
  width: 130px;
}
body.archive.woocommerce-page {
  .content.row {
    width: 90%;
    main.main {
      width: 100%;
      min-width: 100%;
    }
    aside.sidebar {
      display: none;
    }
  }
}
.woocommerce {
  span.woocommerce-Price-amount.amount {
    color: $mint;
  }
  li.reviews_tab.active {
    background: $pink !important;
  }
  div#respond {
    color: $text-color;
  }
  div#comments {
    color: $text-color;
    padding: 1em;
    h2 {
      font-size: 1.5em;
    }
  }
  div#review_form_wrapper {
    padding: 1em;
    h3#reply-title {
      font-size: 1.5em;
    }
  }
  .woocommerce-message {
    border-top-color: $pink;
    &:before {
      color: $pink;
    }
}
a.remove {
  color: $light !important;
  &:hover {
    background: $mint;
  }
}
.woocommerce-info {
  border-top-color: $pink;
  &:before {
    color: $pink;
  }
}
.input-text {
  color: $text-color;
}
div#payment {
    color: $text-color;
}
}
body.single-product,
body.woocommerce-page {
  background: url(/wp-content/uploads/contact_background.jpg) center center no-repeat fixed;
  .content.row {
    max-width: 90% !important;
    main.main {
      max-width: 750px;
      margin: 3em 0;
      float: left !important;
    }
    aside.sidebar {
      float: right;
      margin-top: 5em;
      color: $light !important;
      .widget {
        margin: 1em 0 !important;
        max-width: 300px;
        border-color: $light;
        h3 {
          font-size: 1.25em;
        }
        a.button.wc-forward {
    margin-bottom: 1em;
}
      }
    }
  }
}
.woocommerce-message a.button.wc-forward:hover {
  color: $pink;
  border-color: $button-border;
}
.woocommerce-message a.button.wc-forward:after {
  color: $pink;
}
@media screen and (max-width: 1280px) {
  .single-product, .woocommerce-cart, .checkout, .account {
main.main {
    max-width: 100% !important;
    border-bottom: 1px $light solid;
}
aside.sidebar {
    max-width: 100% !important;
    float: none !important;
    width: 100%;
    margin-top: 0 !important;
    section.widget {
        max-width: 100% !important;
    }
}
  }
}
@media screen and (max-width: $medium) {
  .woocommerce-page, .single-product {
    main.main {
    min-width: initial !important;
    max-width: 100% !important;
}
aside.sidebar {
    float: none !important;
    width: 100%;
    margin: 0 0 2em !important;
    section.widget {
    max-width: 100% !important;
}
}
}
.single-product main.main {
    border-bottom: 1px $light solid;
}
}
