footer {
  padding: 1em 0;
  background: $mint;
  color: $light;
  text-align: center;
  .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    a {
      color: $light;
    }
    p {
      margin: 0;
    }
    .footer-left {
      float: left;
      text-align: left;
      width: 50%;
    }
    .footer-right {
      width: 50%;
      text-align: right;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;
      a.phoneNumber {
        transition: all 400ms;
        &:hover {
          color: $gray;
          transition: all 400ms;
        }
      }
      .social-icons {
        width: 35%;
        display: flex;
        flex-flow: row wrap;
        float: right;
        ul {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          margin: 0;
          li {
            display: inline-block;
            i.fa {
              font-size: 1.5em;
              transition: all 400ms;
              &:hover {
                color: $gray;
                transition: all 400ms;
              }
            } // fa
          } // li
        } // ul
      } // social
    } // footer right
  } // container
} // footer
.topFoot {
  background: $lightgray;
  .container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0;
    aside.row.widget-container {
      padding: 2em 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      width: 100%;
      @include flexbox(space-between);
      align-items: flex-start;
      > * {
        min-width: 290px; // Because iPhone 320 subtract .container 15px per side (30px total)
      }
    }
    .widget {
      vertical-align: top;
      color: $light;
      width: 20%;
      min-width: 20% !important;
      h3 {
        text-align: left;
        font-size: 1.65em;
      }
      ul {
        text-align: left;
        padding-left: 2em;
        li {
          list-style-type: circle;
          p {
            color: $light;
          }
          a {
            color: $light;
          }
        } // li
      } // ul
    } // widget
  } // container
} // top
@media screen and (min-width: $medium) {
  .topFoot {
    .widget-container {
      > * {
        min-width: initial;
      }
    }
    .widget {
      display: inline-block;
      width: 24.7%; // 25% didn't work
    }
  }
  footer {
    .footer-left {
      float: left;
    }
    .footer-right {
      float: right;
      .social-icons {
        // width: 50%;
        ul {
          // width: 50%;
          li {
            display: inline-block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .topFoot {
    .container {
      max-width: 95% !important;
      width: 95%;
      .widget {
        p {
          font-size: .8em;
        }
        a {
          font-size: .8em;
        }
      }
  }
  }
}

@media screen and (max-width: 1100px) {
  // WIDGET
  .topFoot .container {
    .widget {
      width: 100%;
      max-width: 48%;
      margin: 0 0.5em .5em 0;
  }
  .text-4 .textwidget {
    column-count: 2;
    img {
        margin: .15em;
    }
}
  }
  // BOTTOM
  footer .container {
    justify-content: center;
    .footer-left {
      float: none !important;
      width: 100% !important;
      p {
            text-align: center !important;
            margin: 0 auto;
      }
  }

  .footer-right {
      width: 100% !important;
      float: none !important;
      .social-icons {
          float: none !important;
          width: 50% !important;
      }
      a.phoneNumber {
          width: 50%;
          float: none !important;
          text-align: center;
      }
  }
  }
}

@media screen and (max-width: 600px) {
  // WIDGET


.topFoot .container {
  aside.row.widget-container {
    flex-flow: column wrap !important;
    .widget {
        max-width: 100% !important;
        width: 75%;
        margin: 0 auto 1em;
        padding-bottom: 1em;
        border-bottom: 1px $light solid;
    }
    .widget:last-child {
        border: none;
    }
}
}
  // BOTTOM
  .footer-right {
    flex-flow: column wrap !important;
    .social-icons {
      width: 100%;
    }
    a.phoneNumber {
    margin: 0.25em 0;
    width: 100%;
}
}
}
