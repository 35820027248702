// Main Header
header.banner {
  z-index: $header-nav-zindex;
  .topHead {
    background: $header-background-color;
    font-weight: 700;
    padding: 0.5em 0;
    h1#logo {
      margin: 0;
      height: inherit;
      max-width: 500px;
      .navbar-brand {
        width: 100%;
        padding: 0;
        height: inherit;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      } //navbar
    } //logo
  } //top head
} // header

  .rightHead {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    a {
      color: $info-link-color;
      &:hover {
        color: $info-link-color-hover;
      }
    }
    a.phoneNumber {
      line-height: $fields-height;
    }
    .social-icons ul {
      margin: 0;
      li {
        display: inline-block;
        line-height: 1em;
        padding: 0;
      }
      a {
        padding-right: 6px;
        line-height: $fields-height;
        i.fa {
            font-size: 1.5em;
            padding: 0 0.25em;
        }
      }
    }
}


@media screen and (min-width: $small) {
  header.banner {
    .navbar-brand {
      float: none;
    }
    nav {
      clear: both;
      text-align: center;
    }
  }
}

@media screen and (min-width: $medium) {
  header.banner {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      > * {
        flex: 1;
      }
    }
    .navbar-brand {
      float: left;
      h1#logo {

      }
      img {
        float: left;
      }
    }
    #headerMenu {
      float: right;
      max-width: 950px;
    }
    .phoneNumber {
      float: right;
    }
    .social-icons {
      clear: both;
      text-align: right;
      ul {
        padding: 0;
      }
      li {
      }
    }
    nav {
      clear: both;
      float: right;
    }
  }
}

@media screen and (max-width: 1000px) {
  header.banner {
    .container {
    flex-flow: column wrap;
    .leftHead {
      h1#logo {
    margin: 0 auto;
}
    }
    .rightHead {
        width: 100%;
    }
}
  }
  div#headerMenu {
    width: 100%;
}
}

@media screen and (max-width: 675px) {
  header.banner {
    position: static !important;
    box-shadow: none !important;
}
}
